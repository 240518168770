:root {
  /* image preloading - see https://stackoverflow.com/a/14390213/2544629
    use this mixin in an ::after on your class with a background-image that changes on hover/click/etc.
    then in that ::after, also define a `content: url('foo.svg') url('bar.svg');` where foo & bar are the
    not-immediately-visible images you want to preload.
  */
  @define-mixin background-image-preloading {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
  }
}
