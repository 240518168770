.settingsPageWrapper {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 24px;
}

.createButton {
  margin-top: 40px;
}

.radioList {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.radioChoice {
  height: 48px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 12px 24px;
  margin-bottom: 8px;
  border-radius: 12px;
  cursor: pointer;

  border: 1px solid var(--ds-pearl-grey);

  min-width: 140px;

  & + .radioChoice {
    margin-left: 8px;
  }

  &:hover {
    background-color: var(--ds-highlight);
    border-color: transparent;
  }

  &.selectedRadioChoice:not(:hover) {
    background-color: var(--ds-off-white);
  }
}

.radioLabel {
  margin-left: 16px;
}

.radioToggle {
  width: 16px;
  height: 16px;
  background-size: 16px 16px;

  &.fadedToggle {
    opacity: 0.64;
  }
}

.cardPreview {
  width: 80px;
  margin-right: 8px;
  box-shadow: 0 22px 25px 0 rgba(228, 228, 234, 0.22),
    0 9px 23px 0 rgba(228, 228, 234, 0.5);
}

.notificationsTable {
  width: 600px;

  & tr {
    vertical-align: baseline;
  }

  & tr:nth-child(2n) {
    background: var(--ds-pearl-grey);
  }
}

.notifTableLabel {
  padding: 2px 12px;
}

.subsettingWrapper {
  justify-content: center;
}

.betaFeatureInputAndLabel {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & input {
    margin-right: 12px;
  }
}
